<script>
  import Terms from "$lib/admin/components/blocks/helpers/Terms.svelte";
  import Perk from "./blocks/Perk.svelte";
  import LogoNew from "./blocks/LogoNew.svelte";
  import ClickToActionNew from "./blocks/ClickToActionNew.svelte";
  import BonusNew from "./blocks/BonusNew.svelte";
  import { translate } from "$lib/common/helpers/translate";
  import { formatValue } from "$lib/common/helpers/formatValue";
  import { calculateAverageExperience } from "$lib/common/helpers/calculateAverage";
  import RankingReview from "./blocks/RankingReview.svelte";

  export let props = null;
  export let operator = null;
  export let project = null;
  export let visibleOrNot = null;
  export let dataElement = null;
  export let toplistPosition = null;

  let lang = $project.settings.lang ?? "en";
  let primaryColor = operator?.visuals?.[$project.key]?.[$project.settings?.market]?.primary_color;

  // Bonus Data:
  let bonusAmount = operator?.bonus?.casino?.[$project.settings?.market]?.["bonus_amount"];
  let bonusFallback = operator?.bonus?.casino?.[$project.settings?.market]?.["bonus_fallback"];
  let bonus_turnover = operator?.bonus?.casino?.[$project.settings?.market]?.["bonus_turnover"];
  let freeSpinsAmount = operator?.bonus?.casino?.[$project.settings?.market]?.["freespins_amount"];
  let freeSpinsFallback = operator?.bonus?.casino?.[$project.settings?.market]?.["freespins_fallback"];
  let freespinTurnover = operator?.bonus?.casino?.[$project.settings?.market]?.["freespins_turnover"];

  // Perks Data:
  // Wagering requirements
  let withdrawalWagering = operator?.data?.withdrawalLimits?.["min"]?.[$project.settings?.market]?.wagering;

  // Average Chat experience
  let averageChatExperience = calculateAverageExperience(
    Object.values(operator?.data?.ownExperience?.livechatExperience || {})
  );

  // Withdrawal experience
  // Average Speed experience
  let priorityPayments = ["Swish", "Trustly", "Zimpler", "Visa", "Banköverföring"];
  let usedPaymentMethods = new Set(
    Object.values(operator?.data?.ownExperience?.withdrawalExperience || {}).map((round) => round?.payment_method)
  );
  let highestPriorityMethod = priorityPayments.find((method) => usedPaymentMethods.has(method));
  let filteredPaymentMethodResponseTimes = Object.values(operator?.data?.ownExperience?.withdrawalExperience || {}).filter(
    (round) => round?.payment_method === highestPriorityMethod
  );
  let withdrawalExperience = highestPriorityMethod
    ? calculateAverageExperience(Object.values(filteredPaymentMethodResponseTimes || []))
    : "Check with Jesper";
</script>

<div style="display: {visibleOrNot}; height: fit-content;">
  <div class="operator-container">
    <LogoNew {operator} {primaryColor} {dataElement} {toplistPosition} />
    <RankingReview {operator} {project} {props} {dataElement} {toplistPosition} />
    <BonusNew bonus={bonusAmount} fallback={bonusFallback} turnover={bonus_turnover} label="Bonus" gridArea="BONUS1" />
    <BonusNew
      bonus={freeSpinsAmount}
      fallback={freeSpinsFallback}
      turnover={freespinTurnover}
      label="Free Spins"
      gridArea="BONUS2" />
    <Perk
      title={translate("If deposit requirements", lang)}
      value={formatValue(withdrawalWagering, "x")}
      gridArea="PERK1" />
    <Perk
      title={translate("Chat response time", lang)}
      value={formatValue(averageChatExperience, "min", { lang })}
      gridArea="PERK2" />
    <Perk
      title={translate(["withdrawal_Time", highestPriorityMethod], lang)}
      value={formatValue(withdrawalExperience, "min", {
        lang,
      })}
      gridArea="PERK3" />
    <ClickToActionNew {operator} {project} {props} {dataElement} {toplistPosition} gridArea="CTA" />
  </div>
  <div class="terms-container">
    <Terms {operator} />
  </div>
</div>

<style>
  .operator-container {
    display: grid;
    grid-template-columns: 1/6 1/6 1/6 1/6 1/6 1/6;
    grid-template-rows: 100px 70px 70px 70px;
    column-gap: 5px;
    row-gap: 5px;
    grid-template-areas:
      "LOGO LOGO LOGO LOGO LOGO LOGO"
      "BONUS1 BONUS1 BONUS1 BONUS2 BONUS2 BONUS2"
      "PERK1 PERK1 PERK2 PERK2 PERK3 PERK3"
      "CTA CTA CTA CTA CTA CTA";
    position: relative;
    border-radius: 7px;
    border: 2px solid #a5a5a5;
    overflow: hidden;
    padding: 5px;

    @media only screen and (min-width: 992px) {
      padding: 0;
      column-gap: 4px;
      grid-auto-flow: column;
      grid-template-rows: 100px;
      grid-template-columns: 140px 1fr 1.2fr 1.2fr 0.75fr 0.75fr 0.75fr 1.4fr;
      grid-template-areas: "LOGO RATINGREVIEW BONUS1 BONUS2 PERK1 PERK2 PERK3 CTA";
    }
    @media only screen and (min-width: 1200px) {
      grid-template-columns: 1.5fr 1fr 1.2fr 1.2fr 0.75fr 0.75fr 0.75fr 1.4fr;
    }
  }
  .terms-container {
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    max-height: 40px;
    overflow: auto;
    padding-top: 5px;
    margin-bottom: 10px;

    @media only screen and (min-width: 992px) {
      max-height: 100%;
      height: 100%;
      padding-top: 0;
      margin-bottom: 0;
    }
  }
</style>
