<script>
  import { project, postData, projectToplists, orgOperators } from "$lib/services/store";
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Icons from "$lib/main/components/Icons.svelte";
  import Rating from "$lib/admin/components/blocks/helpers/Rating.svelte";
  import Block from "$lib/admin/components/blocks/helpers/Block.svelte";
  import Heading from "$lib/admin/components/blocks/helpers/Heading.svelte";
  import ToplistRow from "$lib/admin/components/blocks/helpers/ToplistRow.svelte";
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";
  import Links from "$lib/admin/components/blocks/blocks/LinksToplist.svelte";
  import CurrencyAmount from "$lib/admin/components/blocks/helpers/CurrencyAmount.svelte";
  import { getOperatorLogo } from "$lib/common/helpers/image";

  let showLinks = false;
  let showMoreCasinos = false;
  const id = Math.random().toString(16);

  export let props = null;
  export let adminView = false;
  export let dataElement = null || "toplist";

  let streamingToplist = [
    {
      name: "C More",
      name_lower: "c-more",
      prosStream: ["Registrera dig & välj C More Sport", "Från 349 kr per mån", "Streama all sport!"],
    },
    {
      name: "Viaplay",
      name_lower: "viaplay",
      prosStream: ["Registrera dig & välj Viaplay Sport", "349 kr per mån", "Streama all sport!"],
    },
  ];
</script>

{#if adminView}
  <Group colspan="4-8" addClass="form-item">
    <Input type="select" label="Toplist type" id={`type-${id}`} on:change bind:value={props.toplistType}>
      <option value="table">Table</option>
      <option value="cards">Cards</option>
      <option value="list">List</option>
    </Input>
  </Group>
  <Group colspan="4-8" addClass="form-item">
    <Input type="select" label="Toplist topic" id={`topic-${id}`} on:change bind:value={props.toplistFocus}>
      <option value="default">Default</option>
      <option value="new-design">New Design</option>
      <option value="casino">Casino (Legacy)</option>
      <option value="casino-bonus">Casino Bonus</option>
      <option value="sport">Sports Bonus</option>
      <option value="freespins-bonus">Freespins Bonus</option>
      <option value="mindeposit">Min. deposit</option>
      <option value="slots">Slots</option>
      <option value="lotto">Lotto</option>
      <option value="streaming">Streaming</option>
      <option value="paymentmethod">Payment Method</option>
      <option value="new-casinos">New Casinos</option>
      <option value="no-account">No account</option>
      <option value="experience">Experience</option>
      <option value="fast-withdrawals">Fast Withdrawals</option>
    </Input>
    {#if props.toplistFocus === "casino"}
      <div></div>
      <div
        style="background-color: #ffaeae; border-radius: 8px; border: 1px solid #f00; color: #941100; padding: 2px 10px; font-size: 15px;">
        <span
          >The "Casino" topic was updated to "Default". Please update it. The topic "Casino" will be discontinued.</span>
      </div>
    {/if}
    {#if props.toplistFocus === "paymentmethod"}
      <Input
        label="↳ Payment Method Provider"
        placeholder="E.g: swish"
        bind:value={props.toplistPaymentMethodProvider} />
    {/if}
  </Group>

  <Group colspan="4-8" addClass="form-item">
    <Input type="select" label="Toplist data" id={`data-${id}`} bind:value={props.toplistId}>
      {#if $projectToplists}
        <option value={null}>None</option>
        {#each $projectToplists as item, i}
          <option value={item.id}>{item.name}</option>
        {/each}
      {/if}
    </Input>
  </Group>
  <Group colspan="4-8" addClass="form-item">
    <Input
      label="Toplist length"
      id={`length-${id}`}
      type="number"
      min="1"
      max="20"
      on:keyup
      bind:value={props.toplistLength} />
  </Group>
  <Links
    adminView={true}
    props={{
      linksType: "toplistSort",
    }} />
{:else if props.toplistType == "table"}
  {@const toplist = $projectToplists?.find((x) => x.id === props.toplistId)?.toplist || []}
  <Block blockClass="block-toplist v1 v0" {props}>
    <div class="toplist-holder">
      <Heading {props} />

      <!-- TODO: create helper function? -->
      {#if props.toplistNavBar}
        <Links
          props={{
            linksType: "toplistSort",
          }}
          bind:showLinks />
      {/if}

      <div class={`toplist ${props.toplistFocus}`} class:show-full={showMoreCasinos}>
        {#if props.toplistId && $projectToplists && $projectToplists.length > 0 && $orgOperators}
          {#each toplist as operatorId, listIndex}
            {@const operator = $orgOperators.find((x) => x.id === operatorId)}
            <ToplistRow
              {props}
              {operator}
              {listIndex}
              {showMoreCasinos}
              {dataElement}
              toplistPosition={listIndex + 1} />
          {/each}
        {:else if props.toplistLength == 1 && props.toplistOperatorId && $orgOperators}
          <ToplistRow
            {props}
            {dataElement}
            operator={$orgOperators.find((x) => x.id === props.toplistOperatorId)}
            forceDisplay={true}
            toplistPosition={0} />
        {/if}
      </div>

      <!-- TODO: create helper function? can use a regular Button component -->
      {#if props.toplistLoadMore && props.toplistLength < toplist.length}
        <div class="grid">
          <div class="g-col-12 g-col-sm-8 g-start-sm-3 g-col-md-6 g-start-md-4 g-col-lg-4 g-start-lg-5">
            <button
              class="load-more"
              on:click={() => {
                showMoreCasinos = !showMoreCasinos;
              }}>
              {#if !showMoreCasinos}
                <Icons name="rotate-cw" strokeWidth="3" /><span><Translate text="Load more casinos" /></span>
              {:else}
                <Icons name="chevron-up" strokeWidth="3" /><span><Translate text="View less casinos" /></span>
              {/if}
            </button>
          </div>
        </div>
      {/if}

      {#if props.toplistLinkUrl}
        <div class="grid">
          <div class="g-col-12 g-col-sm-8 g-start-sm-3 g-col-md-6 g-start-md-4 g-col-lg-4 g-start-lg-5">
            <a
              href={props.toplistLinkUrl}
              class="read-more"
              on:click={() => {
                showMoreCasinos = !showMoreCasinos;
              }}>
              <span>{props.toplistLinkAnchor}</span>
            </a>
          </div>
        </div>
      {/if}
    </div>
  </Block>
{:else if props.toplistType == "cards"}
  <Block blockClass={"block-toplist cards " + props.toplistFocus} {props}>
    <div class="toplist-holder">
      <Heading {props} />

      {#if props.toplistFocus === "streaming"}
        <div class="toplist">
          {#each streamingToplist as stream, i}
            <div class="toplist-item">
              <div class="toplist-container">
                <div class="toplist-img">
                  <img
                    loading="lazy"
                    alt={`Stream sport hos ${stream.name}`}
                    src={`${$project.data.imageFolders.logos}/${stream.name_lower}-featured.png?auto=format`} />
                </div>
                <span>Streama Fotboll hos {stream.name}</span>
                <ol>
                  {#each stream.prosStream as pro}
                    <li>{pro}</li>
                  {/each}
                </ol>
                <a class="toplist-cta" href={`/streama/${stream.name_lower}`} rel="nofollow">Streama nu</a>
              </div>
            </div>
          {/each}
        </div>
      {:else}
        <div class="toplist">
          {#if props.toplistId && $projectToplists && $projectToplists?.length > 0 && $orgOperators}
            {#if $projectToplists.find((x) => x.id === props.toplistId)}
              {@const toplist = $projectToplists
                .find((x) => x.id === props.toplistId)
                ?.toplist}
              {@const filteredToplist = toplist && $postData && $postData.operator_id 
                ? toplist.filter((i) => i !== $postData.operator_id) 
                : toplist || []}
              {#each filteredToplist as operatorId, i}
                {#if i < props.toplistLength || showMoreCasinos}
                  {@const operator = $orgOperators.find((x) => x.id === operatorId)}
                  {@const color = operator?.visuals && operator?.visuals[$project.key] && 
                    operator?.visuals[$project.key]?.[$project.settings?.market]?.primary_color 
                    ? `rgba(${operator?.visuals[$project.key]?.[$project.settings?.market]?.primary_color})` 
                    : 'rgba(0,0,0,0)'}
                  <div class="toplist-item">
                    <div class="toplist-container">
                      <div class="toplist-img toplist-card-img" style="background-color: {color}">
                        <a
                          class="title review"
                          href={($project.data?.categoryPages?.reviews.slug
                            ? "/" + $project.data?.categoryPages.reviews.slug
                            : "") +
                            "/" +
                            operator?.name_lower}
                          style="color: rgba({operator?.visuals[$project.key][$project.settings.market]
                            .secondary_color})">
                          <span>{operator?.["name"]}</span>
                        </a>
                        <a
                          class="img"
                          href={`/${$project.settings.redirectUrl}/${operator?.name_lower}`}
                          target="_blank"
                          rel="nofollow noreferrer noopener">
                          <img loading="lazy" alt={operator?.["name"]} src={getOperatorLogo($project, operator?.logo)} />
                        </a>
                        <div class="toplist-rating">
                          <Rating {operator} />
                        </div>
                      </div>
                      <!-- TODO: include system for campaigns -->
                      <div class="toplist-bonus">
                        <div class="bonus-full">
                          {#if operator?.bonus?.casino?.[$project.settings.market]?.bonus_amount && operator?.bonus?.casino?.[$project.settings.market]?.freespins_amount}
                            <span class="bonus-details"
                              ><Translate text="Up to" />
                              <span class="bonus-amount"
                                ><CurrencyAmount
                                  amount={operator?.bonus?.casino?.[$project.settings.market]?.bonus_amount} /></span>
                              bonus +
                              <span class="free-spins"
                                >{operator?.bonus?.casino?.[$project.settings.market]?.freespins_amount}</span>
                              <Translate text="Free spins" /></span>
                          {:else if operator?.bonus?.casino?.[$project.settings.market]?.bonus_amount}
                            <span class="bonus-details"
                              ><Translate text="Up to" />
                              <span class="bonus-amount"
                                ><CurrencyAmount
                                  amount={operator?.bonus?.casino?.[$project.settings.market]?.bonus_amount} /></span> bonus</span>
                          {:else if operator?.bonus?.casino?.[$project.settings.market]?.freespins_amount}
                            <span class="bonus-details"
                              ><Translate text="Up to" />
                              <span class="free-spins"
                                >{operator?.bonus?.casino?.[$project.settings.market]?.freespins_amount}</span>
                              <Translate text="Free spins" /></span>
                          {:else}
                            {operator?.rating?.[$project.key]?.[$project.settings.market]?.prosCasino[0]}
                          {/if}
                        </div>
                      </div>
                      <div class="toplist-cta">
                        <a
                          href={`/${$project.settings.redirectUrl}/${operator?.name_lower}`}
                          target="_blank"
                          rel="nofollow noreferrer noopener"
                          class="visit">
                          <span>Till casinot</span>
                          <span>Registrering utan krångel</span>
                        </a>
                      </div>
                    </div>
                    <div class="toplist-terms">
                      Reklamlänk | 18+ | <a
                        href={`/${$project.settings.redirectUrl}/${operator?.name_lower}/villkor`}
                        target="_blank"
                        rel="nofollow noreferrer">Regler &amp; villkor gäller</a>
                      | Spela ansvarsfullt |
                      <a href="https://www.stodlinjen.se" target="_blank" rel="nofollow noreferrer noopener"
                        >stodlinjen.se</a>
                    </div>
                  </div>
                {/if}
              {/each}
            {/if}
          {/if}
        </div>
      {/if}
      <div class="grid">
        <div class="g-col-12 g-col-sm-8 g-start-sm-3 g-col-md-6 g-start-md-4 g-col-lg-4 g-start-lg-5">
          {#if props.toplistButtonAnchor && props.toplistButtonUrl}
            <a class="read-more" href={props.toplistButtonUrl}><span>{props.toplistButtonAnchor}</span></a>
          {/if}
        </div>
      </div>
    </div>
  </Block>
{:else if props.toplistType == "list"}
  <Block blockClass="block-toplist list" {props}>
    <Heading {props} />
    <ul>
      {#if props.toplistId && $projectToplists && $projectToplists?.length > 0 && $orgOperators}
        {#each $projectToplists?.find((x) => x.id === props.toplistId)?.toplist || [] as operatorId, i}
          {@const operator = $orgOperators.find((x) => x.id === operatorId)}
          <li>
            <a class={"_" + operator?.name_lower + " logo"} href="/">
              <img
                loading="lazy"
                alt={operator?.name}
                src={$project.data?.imageFolders.operators + operator["name_lower"] + ".png?q=80&auto=format"} />
            </a>
            <h4><a class="logo" href="/">{operator?.name}</a></h4>
            <div class="bonus">
              {operator.bonus?.[props.toplistFocus]?.[$project.settings.market]?.["short"] ?? "No bonus"}
            </div>
            <a class="visit" href="/"><Translate text="Visit" /></a>
          </li>
        {/each}
      {/if}
    </ul>
    {#if props.toplistLinkUrl}
      <div class="grid">
        <div class="g-col-12 g-col-sm-8 g-start-sm-3 g-col-md-6 g-start-md-4 g-col-lg-4 g-start-lg-5">
          <a
            href={props.toplistLinkUrl}
            class="read-more"
            on:click={() => {
              showMoreCasinos = !showMoreCasinos;
            }}>
            <span>{props.toplistLinkAnchor}</span>
          </a>
        </div>
      </div>
    {/if}
  </Block>
{/if}
