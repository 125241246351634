<script lang="ts">
  import { project } from "$lib/services/store";
  import { getColor } from "$lib/common/helpers/getColor";
  import type { Operator } from "@/src/types/components/operators/Operator";
  import { calculateAverageRanking } from "$lib/common/helpers/calculateAverage";
  export let operator: Operator;
  export let blackColor: boolean = false;

  const defaultStarBgColor = "0, 0, 0, 0"; // Fallback star color
  const defaultStarColor = "230, 230, 230, 1"; // Fallback text color

  const starBgColor = getColor(
    defaultStarBgColor,
    operator?.visuals?.[$project.key]?.[$project.settings.market]?.["primary_color"],
    blackColor,
  );
  const starColor = getColor(
    defaultStarColor,
    operator?.visuals?.[$project.key]?.[$project.settings.market]?.["secondary_color"],
    blackColor,
  );
  const textColor = starColor;

  let averageRating = calculateAverageRanking(operator?.data?.rankingSections?.[$project.settings.market]);
</script>

<div
  class="item-rating"
  style="--rating: {averageRating}; --star-color: {starColor}; --star-bg-color: {starBgColor}; --text-color: {textColor}">
  <span class="rating-text"> {averageRating}</span>
  <span class="stars">★★★★★</span>
</div>

<style>
  .item-rating {
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 12px;
    font-weight: 300;
    width: 100%;
    font-size: 0.95rem;
    padding: 0;
    color: var(--star-color);

    >span {
      &:after {
        background: var(--star-bg-color);
      }
    }

    .stars {
      @media only screen and (min-width: 992px) {
        position: relative;
        overflow: hidden;
        color: rgba(0, 0, 0, 0.2) !important;
        
        &:before {
          content: "★★★★★";
          position: absolute;
          top: 0;
          left: 0;
          width: calc(var(--rating) / 5 * 100%);
          overflow: hidden;
          color: #333333 !important;
        }
        
        &:after {
          background: transparent !important;
        }
      }
    }

    .rating-text {
      font-size: 12.5px;
      font-weight: 500;
      color: var(--text-color);
      margin-right: 5px;

      @media only screen and (min-width: 992px) {
        color: #333333;
      }

      &:after {
          display: none !important;
        }
    }
  }
</style>
